import { render, staticRenderFns } from "./add-feedback-suggestion.vue?vue&type=template&id=548072bd&scoped=true&"
import script from "./add-feedback-suggestion.ts?vue&type=script&lang=ts&"
export * from "./add-feedback-suggestion.ts?vue&type=script&lang=ts&"
import style0 from "./add-feedback-suggestion.scoped.scss?vue&type=style&index=0&id=548072bd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548072bd",
  null
  
)

export default component.exports