import { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { SaveFeedback } from '@/resource/model';
import { getFileName, messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { ElButton } from 'element-ui/types/button';
import { Component, Vue } from 'vue-property-decorator';
import { OsFileUpload } from '@/components';
import feedbackService from '@/api/feedback-suggestion/suggestion';
import { ElUploadInternalFileDetail, ElUploadInternalRawFile, HttpRequestOptions } from 'element-ui/types/upload';
import { MinIOFile } from '@/api/minio';
import { FileService } from '@/api';
import { UserModule } from '@/store/modules/user';
import { DataDictionaryService } from '@/api/data-dictionary';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  components: {
    OsFileUpload
  },
  i18n: {
    messages: {
      zh: {
        upload: {
          importExceed: '上传文件数量超出',
          dragUpload: '将文件拖到此处，或',
          clickUpload: '点击上传',
          acceptExtensionTip: '支持',
          maxUploadSizeTip: '单个文件不超过',
          loadingText: '上传中...',
          limit: '最多上传',
          files: '个文件'
        }
      },
      en: {
        upload: {
          importExceed: 'The number of uploaded files exceeds the threshold',
          dragUpload: 'Drag the file here, or',
          clickUpload: ' click on the upload',
          maxUploadSizeTip: 'The maximum file size is ',
          acceptExtensionTip: 'Supported extension:',
          loadingText: 'Uploading...',
          limit: 'Upload up to',
          files: 'files'
        }
      }
    }
  }
})
export default class AddFeedbackSuggestion extends Vue {
  public suggestionForm: SaveFeedback = {
    // 反馈类型
    type: 'BUG',
    // 反馈内容
    content: '',
    // 附件
    fileIdList: []
  };
  public formRules: { [P in keyof Partial<SaveFeedback>]: Array<object> } = {
    content: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('feedback.inputContent')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    type: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('feedback.selectType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };
  public readonly importPath = `platform/upload/installAttachment/`;

  public fileImportOptions: OsFileUploadOptions = {
    autoUpload: false,
    multiple: true,
    limit: 3,
    drag: false,
    hiddenUploadTip: false,
    showFileList: true,
    uploadLoading: false,
    action: '',
    accept: '.png,.jpg,.gif,.jpeg,.pdf,.doc,.docx',
    maxSize: 5,
    fileList: [],
    onRemove: this.removeFile,
    httpRequest: this.customRequest,
    onExceed: this.onExceed,
    beforeUpload: this.beforeUpload,
    loadingText: translation('dialog.importLoadingText')
  };
  public uploadBtnOptions: { name?: string } & Partial<ElButton> = {
    plain: true
  };

  public loading: boolean = false;

  public feedbackTypes: NormalSelectOptions = [];

  public get maxUploadSize(): string {
    if (this.fileImportOptions.maxSize && this.fileImportOptions.maxSize < 1) {
      return `${parseFloat((this.fileImportOptions.maxSize * 1024).toFixed(2))}KB`;
    }
    if (this.fileImportOptions.maxSize && this.fileImportOptions.maxSize > 1024) {
      return `${parseFloat((this.fileImportOptions.maxSize / 1024).toFixed(2))}GB `;
    }
    return `${this.fileImportOptions.maxSize}MB`;
  }

  private assemblyMinIOFileMissions: Array<Promise<void>> = [];
  private toUploadFiles: Array<MinIOFile> = [];

  /**
   * 由于beforeUpload钩子返回false，会触发onRemove，
   * 而为了限制同名文件上传，beforeUpload可能会返回false，导致上传同名文件时，会删除掉之前已经选择过的文件
   * 所以只能自己加一个属性，控制是否删除文件
   */
  private isRepeatFileName = false;

  /**
   * 保存配置
   */
  public async save(): Promise<void> {
    this.suggestionForm.content = this.suggestionForm.content.trim();
    (this.$refs.feedbackForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.loading = true;
      try {
        await this.confirmUpload();
        await feedbackService.post({ ...this.suggestionForm } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.resetForm();
      } catch (error) {
        messageError(error);
      } finally {
        this.loading = false;
      }
    });
  }

  public async confirmUpload(): Promise<void> {
    try {
      (this.$refs.upload as OsFileUpload).submit();
      await Promise.all(this.assemblyMinIOFileMissions);
      if (this.toUploadFiles.length === 0) {
        return;
      }
      const uploadedFiles = await FileService.batchUpload(
        this.toUploadFiles,
        `${this.importPath}${UserModule.account}`
      );

      this.suggestionForm.fileIdList = uploadedFiles.map(x => x.id!);
    } catch (error) {
      if (error) messageError(error);
    } finally {
      this.setLoading(false);
    }
  }
  public resetForm(): void {
    this.suggestionForm = {
      type: 'BUG',
      content: '',
      fileIdList: []
    };
    this.toUploadFiles = [];
    (this.$refs.upload as OsFileUpload).clearFiles();
    (this.$refs.feedbackForm as Form).resetFields();
  }

  /**
   *  设置导入文件时的loading状态
   * @param loading 是否开启loading
   */
  public setLoading(loading: boolean): void {
    this.fileImportOptions.uploadLoading = loading;
  }

  public created(): void {
    this.getFeedbackType();
  }

  public activated(): void {
    this.resetForm();
  }

  public async customRequest(requestOptions: HttpRequestOptions): Promise<void> {
    this.assemblyMinIOFileMissions.push(this.assemblyMinIOFile(requestOptions));
  }
  private async assemblyMinIOFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      const minioFile: MinIOFile = {
        name: requestOptions.file.name,
        stream: Buffer.from(await requestOptions.file.arrayBuffer()),
        metadata: { 'Content-Type': requestOptions.file.type },
        size: requestOptions.file.size,
        originFile: requestOptions.file
      };

      if (!this.toUploadFiles.map(x => x.name).includes(minioFile.name)) {
        this.toUploadFiles.push(minioFile);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 删除已选择的文件
   * @param file 要删除的文件
   * @returns
   */
  private removeFile(file: ElUploadInternalFileDetail, fileList: Array<any>): void {
    this.fileImportOptions.fileList = fileList;
    if (!this.isRepeatFileName) {
      this.toUploadFiles = this.toUploadFiles.filter(x => x.name !== file.name);
      return;
    }
    this.isRepeatFileName = false;
  }
  /**
   * 文件上传前的钩子
   * @param file 文件
   * @returns
   */
  private beforeUpload(file: ElUploadInternalRawFile): boolean {
    const fileNames = this.fileImportOptions.fileList?.map(x => getFileName(x.name)) || [];
    const isExist = fileNames.includes(getFileName(file.name));
    this.isRepeatFileName = isExist;
    return !isExist;
  }

  private onExceed(): void {
    Message.warning(this.$t('upload.importExceed') as string);
  }

  private async getFeedbackType(): Promise<void> {
    try {
      this.feedbackTypes = (await DataDictionaryService.getFeedbackType()).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
}
